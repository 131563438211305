<template>
  <teleport to="body">
    <div class="popup" v-if="togglePopup.active">
      <div :class="['popup-dialog', classes]">
        <button @click="closePopup" class="popup-dialog__close">
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            aria-labelledby="closeIconTitle"
          >
            <title id="closeIconTitle">Close</title>
            <path
              d="M6.34314575 6.34314575L17.6568542 17.6568542M6.34314575 17.6568542L17.6568542 6.34314575"
            ></path>
          </svg>
        </button>
        <h3 class="popup-dialog__header" v-html="header"></h3>
        <div class="popup-dialog__body">
          <slot />
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import helper from "@/helper.js";
export default {
  props: ["header", "active", "classes"],
  setup() {
    const emitter = helper().emitter();
    const togglePopup = ref({
      active: false,
    });

    function closePopup() {
      togglePopup.value["active"] = false;
      emitter.emit("close-popup");
    }

    onMounted(() => {
      emitter.on("model-update", () => {
        togglePopup.value["active"] = true;
      });

      document.addEventListener("keyup", (e) => {
        if (e.key == "Escape") closePopup();
      });
    });

    return {
      togglePopup,
      closePopup,
    };
  },
};
</script>
