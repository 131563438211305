<template>
  <Popup :header="state.header" :classes="mode === 'edit' ? 'lg' : ''">
    <div :class="['grid', mode == 'edit' ? 'gap grid-4-1' : '']">
      <form
        @submit.prevent="addEditClient"
        :class="state.isErrors ? 'form-has-errors' : ''"
      >
        <div v-if="state.isErrors" class="errors">
          <label class="error-text" v-for="(err, k) in state.errors" :key="k">
            {{ err }}
          </label>
        </div>
        <div class="grid gap grid-2-1">
          <div class="form-group">
            <input
              type="text"
              class="form-input"
              v-focus
              :placeholder="$t('message.company_name')"
              v-model="formData.company_name"
            />
            <label>{{ $t("message.company_name") }}</label>
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-input"
              v-focus
              :placeholder="$t('message.type')"
              v-model="formData.type"
            />
            <label>{{ $t("message.type") }}</label>
          </div>
        </div>
        <div class="clearfix"></div>
        <br />
        <div class="grid">
          <div class="form-group">
            <input
              type="text"
              v-focus
              class="form-input"
              :placeholder="$t('message.address')"
              v-model="formData.address_line_1"
            />
            <label>{{ $t("message.address") }}</label>
          </div>
        </div>
        <div class="clearfix"></div>
        <br />
        <div class="grid grid-2 gap">
          <div class="form-group">
            <input
              type="text"
              v-focus
              class="form-input"
              :placeholder="$t('message.first_name')"
              v-model="formData.first_name"
            />
            <label>{{ $t("message.first_name") }}</label>
          </div>
          <div class="form-group">
            <input
              type="text"
              v-focus
              class="form-input"
              :placeholder="$t('message.last_name')"
              v-model="formData.last_name"
            />
            <label>{{ $t("message.last_name") }}</label>
          </div>
        </div>
        <div class="clearfix"></div>
        <br />
        <div class="grid grid-2 gap">
          <div class="form-group">
            <input
              type="text"
              v-focus
              class="form-input"
              :placeholder="$t('message.city')"
              v-model="formData.city"
            />
            <label>{{ $t("message.city") }}</label>
          </div>
          <div class="form-group">
            <input
              type="text"
              v-focus
              class="form-input"
              :placeholder="$t('message.zip_code')"
              v-model="formData.postal_code"
            />
            <label>{{ $t("message.zip_code") }}</label>
          </div>
        </div>
        <div class="clearfix"></div>
        <br />
        <div class="grid grid-2 gap">
          <div class="form-group">
            <input
              type="email"
              v-focus
              class="form-input"
              :placeholder="$t('message.mail')"
              v-model="formData.email"
            />
            <label>{{ $t("message.mail") }}</label>
          </div>
          <div class="form-group">
            <input
              type="text"
              v-focus
              class="form-input"
              :placeholder="$t('message.mobile')"
              v-model="formData.mobile_number"
            />
            <label>{{ $t("message.mobile") }}</label>
          </div>
        </div>
        <div class="clearfix"></div>
        <br />
        <div class="grid grid-2 gap">
          <div class="form-group">
            <input
              type="number"
              v-focus
              class="form-input"
              :placeholder="$t('message.file_number')"
              v-model="formData.file_number"
            />
            <label>{{ $t("message.file_number") }}</label>
          </div>
        </div>
        <div class="clearfix"></div>
        <br />
        <div class="grid grid-2 gap">
          <div class="form-group">
            <input
              type="text"
              class="form-input"
              :placeholder="$t('message.capital')"
              v-focus
              v-model="formData.capital"
            />
            <label>{{ $t("message.capital") }}</label>
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-input"
              :placeholder="$t('message.cnss')"
              v-focus
              v-model="formData.cnss"
            />
            <label>{{ $t("message.cnss") }}</label>
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-input"
              :placeholder="$t('message.niu')"
              v-focus
              v-model="formData.niu"
            />
            <label>{{ $t("message.niu") }}</label>
          </div>
        </div>
        <div class="clearfix"></div>
        <br />
        <button class="form-btn" v-if="!form.loading">
          {{ mode === "edit" ? $t("message.update") : $t("message.create") }}
        </button>
        <button class="form-btn disabled" disabled v-if="form.loading">
          {{ mode === "edit" ? "Updating..." : "Creating..." }}
        </button>
      </form>
      <div v-if="mode === 'edit'">
        <div class="p0-1">
          <!-- Sidebar List(Right sidebar) -->
          <FileList
            @updateTitle="(val) => updateTitle(val)"
            :header="state.header"
            :client="formData"
          ></FileList>
        </div>
      </div>
      <div v-if="mode === 'edit'">
        <div class="text-center">
          <h2 class="clr-gray">Under Progress...</h2>
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
// import { ref } from '@vue/reactivity'
import { onMounted, reactive, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import router from "@/router";
import helper from "@/helper.js";
// Sidebar List(Right sidebar component)
import Popup from "@/components/Popup.vue";
import FileList from "./FileList.vue";
import { v4 as uuidv4 } from "uuid";
import { useI18n } from "vue-i18n";

export default {
  components: { Popup, FileList },
  props: ["mode", "payload", "feature"],
  setup(props) {
    const { t } = useI18n();
    // console.log(this.i18n);
    const form = ref({
      loading: false,
    });
    const state = reactive({
      isErrors: false,
      errors: [],
      header: t("message.create_new_client"),
    });
    const store = useStore();
    const formData = ref({
      parent_id: store.state.auth.user.id,
      // file_number: null,
      // conpany_name: null,
      // first_name: null,
      // last_name: null,
      // email: null,
      // address_line_1: null,
      // address_line_2: null,
      // city: null,
      // postal_code: null,
      // mobile_number: null,
    });
    const emitter = helper().emitter();
    // const popupTriggers = ref({
    //   trigger: false
    // })

    function updateTitle(val) {
      state.header = val || t("message.create_new_client");
    }

    async function addEditClient() {
      console.log(router);
      form.value.loading = true;
      state.isErrors = false;
      state.errors = [];
      formData.value.mode = props.mode;
      formData.value.uuid = uuidv4();
      formData.value.status = "ACTIVE";
      if (props.mode == "edit") {
        if (
          router.currentRoute.value.name == "MyClients" ||
          router.currentRoute.value.name == "ArchivedClients"
        ) {
          formData.value.feature = "Client";
        } else if (
          router.currentRoute.value.name == "MySuppliers" ||
          router.currentRoute.value.name == "ArchivedSuppliers"
        ) {
          formData.value.feature = "Supplier";
        } else if (
          router.currentRoute.value.name == "MyEmployees" ||
          router.currentRoute.value.name == "ArchivedEmployees"
        ) {
          formData.value.feature = "Employee";
        }
      } else {
        formData.value.feature = props.feature;
      }
      await store
        .dispatch("client/addEditClient", formData.value)
        .then((res) => {
          form.value.loading = false;
          if (res.status == "success") {
            if (props.mode == "edit") {
              if (
                router.currentRoute.value.name == "MyClients" ||
                router.currentRoute.value.name == "ArchivedClients"
              ) {
                router.push({ name: "MyClients" });
              } else if (
                router.currentRoute.value.name == "MySuppliers" ||
                router.currentRoute.value.name == "ArchivedSuppliers"
              ) {
                router.push({ name: "MySuppliers" });
              } else if (
                router.currentRoute.value.name == "MyEmployees" ||
                router.currentRoute.value.name == "ArchivedEmployees"
              ) {
                router.push({ name: "MyEmployees" });
              }
            } else {
              if (props.feature == "Client") {
                router.push({ name: "MyClients" });
              } else if (props.feature == "Supplier") {
                router.push({ name: "MySuppliers" });
              } else {
                router.push({ name: "MyEmployees" });
              }
            }
            emitter.emit("close-popup");
            store.commit("notification/SEND_NOTIFICATION", res);
          } else {
            state.isErrors = true;
            state.errors.push(res.message);
          }
        });
    }
    onMounted(() => {
      emitter.emit("model-update");
      if (props.mode === "edit") {
        formData.value = props.payload;
      }
      if (props.feature === "Supplier") {
        state.header = t("message.suppliers");
      } else if (props.feature === "Employee") {
        state.header = t("message.employees");
      }
    });

    // const togglePopup = (trigger) => {
    //   popupTriggers.value['trigger'] = trigger
    // }
    // function updateModel() {
    // }

    return {
      // Popup,
      // popupTriggers,
      // togglePopup,
      // updateModel,
      // activePopup
      state,
      addEditClient,
      updateTitle,
      formData,
      form,
    };
  },
};
</script>
