<template>
  <div class="popup-content__overlay">
    <a
      tabindex="0"
      class="back-link"
      @keyup.enter="backToPage"
      @click="backToPage"
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-arrow-left"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#597e8d"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <line x1="5" y1="12" x2="19" y2="12" />
        <line x1="5" y1="12" x2="11" y2="18" />
        <line x1="5" y1="12" x2="11" y2="6" /></svg
      >&nbsp;{{ $t("message.back") }}</a
    >
    <div class="clearfix"></div>
    <br />
    <div class="text-center">
      <table class="table" v-if="subUsers.length">
        <thead>
          <th>{{ $t("message.name") }}</th>
          <th>{{ $t("message.actions") }}</th>
        </thead>
        <tbody>
          <tr v-for="(user, key) in subUsers" :key="key">
            <td>{{ user.name }}</td>
            <td style="width: 250px">
              <button
                v-if="!subUserIds.includes(user.id)"
                class="form-btn xs"
                @click="attachSubUserToFile(user)"
              >
                {{ $t("message.attach") }}
              </button>
              <button
                class="form-btn xs danger"
                @click="detachSubUserFromFile(user)"
                v-else
              >
                {{ $t("message.detach") }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <h4 class="lbl-gray__empty">{{ $t("message.this_list_is_empty") }}!</h4>
      </div>
    </div>
    <div class="clearfix"></div>
    <br />
  </div>
</template>

<script>
import { computed, onMounted, reactive } from "@vue/runtime-core";
import { useStore } from "vuex";
import helper from "@/helper";
export default {
  props: ["file"],
  setup(props) {
    const emitter = helper().emitter();
    const store = useStore();
    const state = reactive({
      attachedUsers: [],
    });
    const subUsers = computed(() => store.state.account.accounts);
    let subUserIds = computed(() =>
      props.file.client_subusers.map((u) => u.user_id)
    );
    function backToPage() {
      emitter.emit("backToMainPopup", "Create a ");
    }
    function attachSubUserToFile(subuser) {
      subuser.client_id = props.file.id;
      store
        .dispatch("client/attachSubUser", { subuser, file_id: props.file.id })
        .catch(() => {
          alert("Something went wrong!");
        });
    }
    function detachSubUserFromFile(subuser) {
      subuser.client_id = props.file.id;
      store.dispatch("client/detachSubUser", {
        subuser,
        file_id: props.file.id,
      });
    }
    onMounted(async () => {
      if (!store.state.account.accounts.length) {
        await store.dispatch("account/fetchAllUsersAccount");
      }
    });
    return {
      state,
      subUsers,
      backToPage,
      attachSubUserToFile,
      detachSubUserFromFile,
      subUserIds,
    };
  },
};
</script>
