<template>
  <ul class="list-action">
    <li>
      <a
        tabindex="0"
        @keyup.enter="toggleAction('user', $t('message.attach_subuser'))"
        @click="toggleAction('user', $t('message.attach_subuser'))"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-user"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="7" r="4" />
          <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /></svg
        >&nbsp;{{ $t("message.users") }}</a
      >
      <teleport v-if="state.action_toggle == 'user'" to=".popup-dialog__body">
        <Subuser :file="client" />
      </teleport>
    </li>
    <li>
      <router-link :to="`/journal/${client.uuid}/new`" tabindex="0"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-notebook"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path
            d="M6 4h11a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-11a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1m3 0v18"
          />
          <line x1="13" y1="8" x2="15" y2="8" />
          <line x1="13" y1="12" x2="15" y2="12" />
        </svg>
        &nbsp;{{ $t("message.create_journal") }}</router-link
      >
    </li>
    <li>
      <router-link :to="`/journal/${client.uuid}/list`"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-notes"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x="5" y="3" width="14" height="18" rx="2" />
          <line x1="9" y1="7" x2="15" y2="7" />
          <line x1="9" y1="11" x2="15" y2="11" />
          <line x1="9" y1="15" x2="13" y2="15" />
        </svg>
        &nbsp;{{ $t("message.my_journals") }}</router-link
      >
    </li>
    <li>
      <router-link :to="`/journal/${client.uuid}/ledger/third`"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-coin"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="9" />
          <path
            d="M14.8 9a2 2 0 0 0 -1.8 -1h-2a2 2 0 0 0 0 4h2a2 2 0 0 1 0 4h-2a2 2 0 0 1 -1.8 -1"
          />
          <path d="M12 6v2m0 8v2" />
        </svg>
        {{ $t("message.ledger_of_third") }}</router-link
      >
    </li>
    <li>
      <router-link :to="`/journal/${client.uuid}/ledger/general`"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-cash-banknote"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="3" />
          <rect x="3" y="6" width="18" height="12" rx="2" />
          <line x1="18" y1="12" x2="18.01" y2="12" />
          <line x1="6" y1="12" x2="6.01" y2="12" />
        </svg>
        &nbsp;{{ $t("message.general_ledger") }}</router-link
      >
    </li>
    <li>
      <router-link :to="`/${client.uuid}/balance`"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-report"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697" />
          <path d="M18 14v4h4" />
          <path d="M18 11v-4a2 2 0 0 0 -2 -2h-2" />
          <rect x="8" y="3" width="6" height="4" rx="2" />
          <circle cx="18" cy="18" r="4" />
          <path d="M8 11h4" />
          <path d="M8 15h3" />
        </svg>
        &nbsp;{{ $t("message.balances") }}</router-link
      >
    </li>
    <li>
      <a
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-receipt-tax"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1="9" y1="14" x2="15" y2="8" />
          <circle cx="9.5" cy="8.5" r=".5" fill="currentColor" />
          <circle cx="14.5" cy="13.5" r=".5" fill="currentColor" />
          <path
            d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2"
          />
        </svg>
        &nbsp;{{ $t("message.tax_return") }}</a
      >
    </li>
    <li>
      <a
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-mail"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x="3" y="5" width="18" height="14" rx="2" />
          <polyline points="3 7 12 13 21 7" />
        </svg>
        &nbsp;{{ $t("message.send_by_mail") }}</a
      >
    </li>
    <li>
      <a @click="updateClientStatus()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-archive"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x="3" y="4" width="18" height="4" rx="2" />
          <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10" />
          <line x1="10" y1="12" x2="14" y2="12" />
        </svg>
        &nbsp;{{ client.status === "ARCHIVED" ? "Désarchiver" : "Archiver" }}
      </a>
    </li>
    <li v-if="client.status === 'ARCHIVED'">
      <a @click="deleteClient(client)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-trash"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="#597e8d"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1="4" y1="7" x2="20" y2="7" />
          <line x1="10" y1="11" x2="10" y2="17" />
          <line x1="14" y1="11" x2="14" y2="17" />
          <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
          <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg
        >&nbsp;{{ $t("message.delete") }}
      </a>
    </li>
    <li>
      <a
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-hierarchy"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="5" r="2" />
          <circle cx="5" cy="19" r="2" />
          <circle cx="19" cy="19" r="2" />
          <path d="M6.5 17.5l5.5 -4.5l5.5 4.5" />
          <line x1="12" y1="7" x2="12" y2="13" />
        </svg>
        &nbsp;{{ $t("message.status") }}</a
      >
    </li>
  </ul>
</template>

<script>
import { useStore } from "vuex";
import helper from "@/helper";
import { reactive } from "@vue/reactivity";
import Subuser from "./action/subUser.vue";
import { onMounted } from "@vue/runtime-core";
export default {
  props: ["client", "header"],
  components: { Subuser },
  setup(props, ctx) {
    const store = useStore();
    const state = reactive({
      action_toggle: null,
    });
    const emitter = helper().emitter();
    console.log(props.client.status);
    async function updateClientStatus() {
      if (props.client.status == "ACTIVE") {
        let res = await store.dispatch("client/archieveClient", {
          client_id: props.client.id,
        });
        store.commit("notification/SEND_NOTIFICATION", res);
        emitter.emit("close-popup");
      } else {
        let res = await store.dispatch("client/unarchieveClient", {
          client_id: props.client.id,
        });
        store.commit("notification/SEND_NOTIFICATION", res);
        emitter.emit("close-popup");
      }
    }
    const toggleAction = (command, title) => {
      state.action_toggle = command;
      ctx.emit("updateTitle", title);
    };

    const deleteClient = (client) => {
      if (confirm("Are you sure you want to delete this client?")) {
        store.dispatch("client/deleteClient", client).then((res) => {
          emitter.emit("close-popup");
          store.commit("notification/SEND_NOTIFICATION", res);
        });
      }
    };

    onMounted(() => {
      emitter.on("backToMainPopup", () => {
        ctx.emit("updateTitle", null);
        state.action_toggle = null;
      });
    });

    return {
      state,
      updateClientStatus,
      toggleAction,
      deleteClient,
    };
  },
};
</script>
